import { ApplicationContext } from '../ApplicationContext';
import { localStoreProvider } from '../data/LocalStoreProvider';
import { IStore } from '../data/Store';
import { FileStoreClient } from '../data/FileStoreClient';

export class SingleSourceModelTestFactory {
  public static create(store: IStore, fileStoreClient: FileStoreClient) {
    localStoreProvider.setPersistedStore(store);
    const applicationState = ApplicationContext.initialize(
      { sub: '1234', name: 'Test User', email: 'test@test.com' },
      store,
      fileStoreClient,
      ['NDM', 'CD'],
    );
    // const clipboard = new Clipboard(
    //   new TestClipboard(),
    //   applicationState.entityRepository,
    //   applicationState.entitySelectionTracker,
    //   applicationState.messageBus,
    // );
    // Object.assign(applicationState, { clipboard: clipboard });
    return applicationState;
  }
}
